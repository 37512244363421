import Vue from 'vue'
import App from './App.vue'
import './plugins/element.js'
import router from './router.js'

import './assets/site.css'
import './assets/fonts/iconfont.css'

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');



//全局配置ajax   
(function () {
  $.ajaxSetup({
    xhrFields: {
      withCredentials: true  //支持附带详细信息
    },
    crossDomain: true,//请求偏向外域
    // contentType: 'application/x-www-form-urlencoded',
    error: res => {
      //请求异常处理登录
      if (res.status == 599) {
        //当前用户没有登录
        vueParent.$message({
          type: 'error',
          message: '您没有登录或登录超时',
          onClose: () => {
            vueParent.$router.replace('login');
          }
        });
      }
    }
  });


  //全局配置腾讯云上传处理
  setTimeout(() => {
    window.TencentConfig = {
      Bucket: 'com-1318622311',  /* 存储桶，必须字段 */
      Region: 'ap-nanjing',    /* 存储桶所在地域，必须字段 */
    //  domain: 'https://najing-1303892658.cos.ap-nanjing.myqcloud.com',movie.muzixiaohua.cn
      domain: 'http://movie.muzixiaohua.cn/',
      SecretId: 'AKIDqVxLW3Yu46wd9XhmhT2cKxVyVxHWXPm5',
      SecretKey: 'FYwFMNH7wYwBIGJKF2jEaovobHojQs6B'
    }
  }, 100);

})();

