import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "main",
    component: () => import("./views/Main.vue"),
    children: [
      {
        path: "",
        name: 'home',
        component: () => import("./views/Home.vue")
      },
      {
        path: "/menu",
        name: "menu",
        component: () => import("./views/Menu.vue")
      },
      {
        path: "/role",
        name: "role",
        component: () => import("./views/sys/Role.vue")
      },
      {
        path: "/manager",
        name: "manager",
        component: () => import("./views/sys/Manager.vue")
      },
      {
        path: "/navigation",
        name: "navigation",
        component: () => import("./views/sys/Navigation.vue")
      },
      {
        path: "/siteconfig",
        name: "siteconfig",
        component: () => import("./views/sys/SiteConfig.vue")
      },
      {
        path: "/banner",
        name: "banner",
        component: () => import("./views/sys/Banner.vue")
      },
      {
        path: "/video",
        name: "video",
        component: () => import("./views/video/VideoInfo.vue")
      },
      {
        path: "/japanese",
        name: "japanese",
        component: () => import("./views/video/Japanese.vue")
      },
      {
        path: "/japaneseFile",
        name: "japaneseFile",
        component: () => import("./views/video/JapaneseFile.vue")
      },
      {
        path: "/japaneseJudge",
        name: "japjapaneseJudgeanese",
        component: () => import("./views/video/Judge.vue")
      },

      {
        path: "/teacher",
        name: "teacher",
        component: () => import("./views/que/Teacher.vue")
      },
      {
        path: "/question",
        name: "question",
        component: () => import("./views/que/Question.vue")
      }, {
        path: "/strategy",
        name: "strategy",
        component: () => import("./views/que/Strategy.vue")
      },
      {
        path: "/articleInfo",
        name: "articleInfo",
        component: () => import("./views/sys/ArticleInfo.vue")
      },
      {
        path: "/articleStatic/:id",
        name: "articleStatic",
        component: () => import("./views/sys/ArticleStatic.vue"),
      },
      {
        path: "/sys_BasicData",
        name: "sys_BasicData",
        component: () => import("./views/abroad/Sys_BasicData.vue"),
      },
      {
        path: "/schoolnews",
        name: "schoolnews",
        component: () => import("./views/abroad/SchoolNews.vue"),
      },
      {
        path: "/school",
        name: "school",
        component: () => import("./views/abroad/School.vue"),
      },
      {
        path: "/college",
        name: "college",
        component: () => import("./views/abroad/College.vue"),
      },
      {
        path: "/university",
        name: "university",
        component: () => import("./views/abroad/University.vue"),
      },
      {
        path: "/highSchool",
        name: "highSchool",
        component: () => import("./views/abroad/HighSchool.vue"),
      },
      {
        path: "/case",
        name: "case",
        component: () => import("./views/abroad/Case.vue"),
      },
      // 其他文章用的这个控制器路由。
      {
        path: "/applyrecord",
        name: "applyrecord",
        component: () => import("./views/other/ApplyRecord.vue"),
      },
      {
        path: "/imginfo",
        name: "imginfo",
        component: () => import("./views/other/ImgInfo.vue"),
      },


      //  url增加
      {
        path: "/url",
        name: "url",
        component: () => import("./views/video/Url.vue")
      },
      //  增加其他文章 
      {
        path: "/articleother",
        name: "articleother",
        component: () => import("./views/video/ArticleOther.vue")
      },
      {
        path: "/stuCaseCollege",
        name: "stuCaseCollege",
        component: () => import("./views/abroad/StuCaseCollege.vue")
      },
      {
        path: "/catalogue",
        name: "catalogue",
        component: () => import("./views/video/Catalogue.vue")
      },
      {
        path: "/selectRecord",
        name: "selectRecord",
        component: () => import("./views/other/SelectRecord.vue")
      },
      {
        path: "/collegeArea",
        name: "collegeArea",
        component: () => import("./views/abroad/CollegeArea.vue")
      },

    ]
  },
  {
    path: "/login",
    name: "login",
    component: () => import("./views/Login.vue")
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
