<template>
   <router-view></router-view>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {
    //跳转登录
    var _this = this;
    // _this.$router.push('login');
  }
};
</script>